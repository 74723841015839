<template>
  <section class="hero-section">
    <!-- black overlay with 50% opacity on top of image -->
    <NuxtPicture
      class=""
      format="webp"
      alt="hero-image"
      fit="cover"
      :img-attrs="{
        class:
          'inset-0 absolute h-full  w-full object-cover object-left md:object-center -z-1 bg-[#312B58]',
      }"
      :src="heroSection.src"
      placeholder
      preload
    ></NuxtPicture>
    <!-- <NuxtImg
      class="inset-0 absolute h-full max-h-[1000px] w-full object-cover object-left md:object-center -z-1"
      alt="hero-image"
      placeholder
      :src="heroSection.src"
</NuxtImg> -->
    <div class="min-h-[inherit] w-full z-0">
      <div class="container mx-auto flex flex-col h-full min-h-[inherit]">
        <div class="hero-text pt-40 pb-24 flex flex-1 flex-col items-start">
          <div
            class="rounded-full bg-white text-black px-3 py-0.5 text-sm md:text-base mx-auto md:mx-0"
            v-html="heroSection.banner"
          ></div>
          <h1
            class="text-white text-3xl md:text-5xl w-full md:w-1/2 pt-4 text-center md:text-start whitespace-pre-line"
          >
            <span
              v-for="item in heroSection.title"
              :class="
                item.isFancy
                  ? 'primary-gradient bg-clip-text text-transparent'
                  : ''
              "
            >
              {{ item.text }}
            </span>
          </h1>

          <Button
            :aria-label="heroSection.buttonText"
            class="bg-gradient-to-r from-primary to-primary-dark mt-4 rounded-md flex gap-2 mx-auto md:mx-0"
            @click="openModal()"
          >
            {{ heroSection.buttonText }}
            <Icon icon="mdi-arrow-right" class="text-lg"></Icon>
          </Button>
        </div>
        <HeroActions class="flex-none"></HeroActions>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { Icon } from "@iconify/vue/dist/iconify.js";
import { heroSection } from "~/lib/constants";
import { useModal } from "vue-final-modal";
import ContactModal from "~/components/global/ContactModal.vue";

const openModal = async () => {
  const modal = useModal({
    component: ContactModal,
  });

  await modal.open();
};
</script>

<style lang="scss" scoped>
.hero-section {
  @apply w-full min-h-[500px] md:min-h-[800px] relative md:max-h-[1000px];
  min-height: min(calc(100dvh - 80px), 800px);
}
</style>
